<template>
  <v-stepper v-model="currentIndex">
    <v-stepper-header>
      <template v-for="(step, index) in steps">
        <v-stepper-step
          :key="step.key"
          :step="index + 1"
        >
          {{ step.title }}
        </v-stepper-step>
      </template>  
    </v-stepper-header>

    <v-stepper-items>
      <template v-for="(step, index) in steps">
        <v-stepper-content
          :key="step.key"
          :step="index + 1"
        >
          <template v-for="(uiField, index2) in step.uiFields">
            <uiField
              :key="uiField.key"
              v-model="step.uiFields[index2]"
            />
          </template>

          <v-btn
            color="primary"
            @click="processStep"
          >
            {{ $t('continue') }}
          </v-btn>

          <v-btn text>
            {{ $t('cancel') }}
          </v-btn>
        </v-stepper-content>
      </template>
    </v-stepper-items>
  </v-stepper>
</template>

<script>

import uiField from "./uiField"

export default {
    name: 'SignupSteps',
    components: {
        uiField
    },
    data () {
      return {
        currentStep: 'yourself',
        steps: [
            {
                key: 'yourself',
                title: 'Tell us about yourself',
                uiFields: [
                    {
                        label: this.$t('email'),
                        key: 'email',
                        type: 'email',
                        value: null,
                    },
                    {
                        label: this.$t('typeOfBusiness'),
                        key: 'business_type',
                        type: 'select',
                        options: [
                            {
                                key: 'restaurant',
                                label: this.$t('restaurant')
                            },
                            {
                                key: 'hotel',
                                label: this.$t('hotel')
                            },
                            {
                                key: 'personal_services',
                                label: this.$t('personalServices')
                            }
                        ],
                        value: null,
                    }
                ]
            },
            {
                key: 'organization',
                title: this.$t('organization'),
                uiFields: [

                ]
            },
            {
                key: 'branding',
                title: this.$t('branding'),
                uiFields: [

                ]
            },
            {
                key: 'account',
                title: this.$t('accounting'),
                uiFields: [

                ]
            },
        ]
      }
    },
    methods: {
        processStep() {
            this.currentStep = this.nextStep.key
        }
    },
    computed: {
        nextStep() {
            return this.steps[this.currentIndex + 1]
        },
        currentIndex() {
            let currentKey = 1

            for(let i = 1; i < this.steps.length; i++) {
                if(this.steps[i].key === this.currentStep) {
                    currentKey = i
                }
            }

            return currentKey
        }
    }
}
</script>